import React from 'react';
import { GetTranslation } from '../modules/stam-strap/components/partials';

export const maudioguid = {
    name: "maudioguid",
	clean:"audioguid",
    actions: [
        "all", "id", "add", "update", "trash", "reset"
    ],
    model: {
        game:{
            name:"Application",
            type:"select",
            data:{
                isMulti:true
            },
            modelType:"game",
            update:true,
            add:true
        },
        short: {
            name: "Short",
            type: "text",
            placeholder: "Short",
            add: true,
            update: true
        },
        mbuilding:{
            name:"Tour",
            type:"select",
            data:{
                isMulti:false
            },
            modelType:"mbuilding",
            update:true,
            add:true
        },
		msphere:{
            name:"Sphere",
            type:"select",
            data:{
                isMulti:false
            },
            modelType:"msphere",
            update:true,
            add:true
        },
        mpoi: {
            name:"Poi",
            type:"select",
            data:{
                isMulti:false
            },
            modelType:"mpoi",
            update:true,
            add:true
        },
        title: {
            name: "Nom",
            placeholder: "Nom de l'objet",
            type: "translationtext",
            //add: true,
            update: true
        },
        subtitle: {
            name: "Sous-titre",
            placeholder: "Sous-titre de l'objet",
            type: "translationtext",
            //add: true,
            update: true
        },
        description: {
            name: "Description",
            type: "textarea",
            placeholder: "Description",
            update: true
        },
		media:{
            name:"Media",
            type:"select",
            data:{
                isMulti:false
            },
            modelType:"ffile",
            update:true
        },
        angle: {
            name: "Angle",
            type: "text",
            placeholder: "Angle",
            update: true
        },
        duration: {
            name: "Durée",
            type: "text",
            placeholder: "Durée",
            update: true
        }
    },
    filters:{
        default:{
            filter:"searchMaudioguidByShort",
            quantity:20,
            state:"maudioguid.maudioguids",
            value:"_id",
            label:"short"
        },
		all:[
			{
				type:"search",
				size:25,
				name:"searchMaudioguidByShort",
				placeholder:"Recherche par short"
			},
		]
    },
    display:{
        selectDefault:(e) => {
            return {
                label:e.name,
                value:e._id,
                force:true
            }
        }
    },
	properties:[
		{ short: "short", name: "Short", width: "40%", category: "classic"},
		{ short: "title", name: "Nom", width: "40%", category: "classic"},
        { short: "angle", name: "Angle", width: "10%", category: "classic"},
		{ short: "actions", name: "Actions", width: "10%", category: "actions" }
	],
	renderTd:(e) => {
		return {
			id: e._id,
			key: "maudioguid_"+e._id,
			short: e.short,
			name: <GetTranslation quick={e.title && e.title.translations} />
		}
	}
}