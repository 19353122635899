import { getData } from './index';
import {
    BLOCK_ALL,
    BLOCK_ID,
    BLOCK_RESET,
    BLOCK_ERROR
} from './types';

import { dataToUrl } from './../../../modules/stam-strap/utils'

export function getBlockAll(data) {
    const url = '/block/all'+dataToUrl(data);
    return dispatch => getData(BLOCK_ALL, null, BLOCK_ERROR, true, url, dispatch);
}

export function getBlockById(id){
    const url = '/block/id/'+id;
    return dispatch => getData(BLOCK_ID, null, BLOCK_ERROR, true, url, dispatch);
}

export function resetBlock() {
	return dispatch => dispatch({ type: BLOCK_RESET });
}