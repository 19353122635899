//Redux
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//General
import store from '../../../../index'
//ts-strap
import { recompose } from '../../lib'

export class TranslationStrap{
    constructor(langages, langageTraductions){
        this.langages = langages;
        this.langageTraductions = langageTraductions;
    }

    searchTranslation(translation, langage){
        let result = translation;
        
        if(translation === undefined){

        }else{
            result = recompose(this.langageTraductions[langage], translation);
        }
        
        if(result === undefined){
            result = translation;
        }
        return result;
    }

    getTranslation = (short, langage, firstletter) => {

        let temp = langage.currentLangage;
        //temp = "fr"

        let translation = this.searchTranslation(short, temp);
    
        if(firstletter){
            translation = translation.charAt(0).toUpperCase() + translation.slice(1);
        }
        return translation;
    }

    getTranslationFunction = (short, firstletter) => {
        //console.log(store.getState().langage)
        let temp = store.getState().langage;
        //temp = "fr"
        return this.getTranslation(short, temp, firstletter);
    }
}

class GetTranslationStrap extends Component {
    render() {

        const { quick, short, langage, firstletter, getTranslation } = this.props;

        let finalQuick = null;

        if(quick && quick.length > 0){
            for (let q of quick) {
                if(q.langage === langage.currentLangage){
                    finalQuick = q.value;
                    break;
                }
            }
        }

        return (
            <React.Fragment>
                { finalQuick ? finalQuick : getTranslation(short, langage, firstletter) }
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        langage: state.langage,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GetTranslationStrap);