import { actions } from 'modules/stam-strap/stuva/index.js';

const elementActions = actions["translation"];
const getAll = elementActions.getAll;

const tsstuva = {
    translation:{
        getAll:getAll
    },
    actions:actions
}

export default tsstuva;