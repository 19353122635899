import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
import {Full} from './../../../../modules/ts-strap/components/partials/';
import {TileContainer, Tile, TileHeader, TileBody} from './../../../../modules/ts-strap/components/partials/';

//import moment from 'moment';

import { Form, Input } from './../../../../modules/ts-strap/components/form';
//import DataTable from 'modules/ts-strap/components/form/DataTable';
import { Media, Row, Col, Button } from 'react-bootstrap';

import { resetCluster, getClusterById, getClusterElementsByCluster } from '../../actions/cluster';
import { resetBlock, getBlockAll } from '../../actions/block'

import { Avatar } from './../../../../modules/ts-strap/components/display/';

import ViewerLevel from '../three/ViewerLevel';

class Cluster extends Component {

    /*componentWillMount(){
        this.props.resetCluster();
        this.props.resetBlock();
    }*/

    componentDidMount() {
        const { match, resetCluster, getClusterById, getClusterElementsByCluster, resetBlock, getBlockAll } = this.props;
        const idCluster = match && match.params && match.params.idcluster;

        resetCluster();
        resetBlock();

        if (idCluster) {
            getClusterById(idCluster);
            getClusterElementsByCluster(idCluster);
            getBlockAll();
        }
    }

    render() {
        const { match, cluster, clusterElements, blocks, menu, error, message } = this.props;
        const id = match && match.params && match.params.id;
        const idCluster = match && match.params && match.params.idcluster;

        const sizeInfo = 3, sizeInfo2 = 9;


        let portlet = {
            title:"Clusters",
            links:[
                /*{name:"Sessions", path:"/"+id+"/sessions"},*/
                {name:"Cluster", path:"/"+id+"/cluster/"+idCluster}
            ]
        }

        /*if(session){
            portlet.links[1].name = "";
        }*/

        return (
            <React.Fragment>
                <MenuLeft menu={menu} />
                <HeaderTop menu={menu} />

                {cluster && clusterElements ? (

                    <Full portlet={portlet}>

                        <Form
                            initialValues={{
                                name: (cluster.name) || '',
                                short: (cluster.short) || ''
                            }}
                        >
                            <TileContainer>
                                <Tile size={"full"}>
                                    <TileBody>
                                        <Row>
                                            <Col sm="6">
                                                <Media>
                                                    <Avatar />
                                                    <Media.Body>
                                                        <h3>{cluster.name}</h3>
                                                        <p className="my-0">{cluster.game.name}</p>
                                                    </Media.Body>
                                                </Media>
                                            </Col>
                                            <Col sm="6" className="text-right">
                                                {cluster.type}<br />
                                            </Col>
                                        </Row>
                                    </TileBody>
                                </Tile>
                            </TileContainer>

                            <Row>
                                <Col lg="8" className="tile-col">
                                    {/* Options */}
                                    <TileContainer>
                                        <Tile size={'full'}>
                                            <TileHeader title="Preview" border={true} />
                                            <TileBody>
                                                {(blocks && clusterElements && cluster) ? (
                                                    <ViewerLevel
                                                        clusterElements={clusterElements}
                                                        blocks={blocks}
                                                    />
                                                ) : null }
                                            </TileBody>
                                        </Tile>
                                    </TileContainer>

                                </Col>

                                {/* Options */}
                                <Col lg="4" className="tile-col">
                                    {
                                        <TileContainer>
                                            <Tile size={'full'}>
                                                <TileHeader title="Options" border={true} />
                                                <TileBody>
                                                    <Row>
                                                        <Col sm={sizeInfo} className="my-auto text-right">
                                                            Nom
                                                        </Col>
                                                        <Col sm={sizeInfo2}>
                                                            <Input name="name" type="text" />
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col sm={sizeInfo} className="my-auto text-right">
                                                            Short
                                                        </Col>
                                                        <Col sm={sizeInfo2}>
                                                            <Input name="short" type="text" />
                                                        </Col>
                                                    </Row>

                                                </TileBody>
                                            </Tile>
                                        </TileContainer>
                                    }

                                    {/* Save */}
                                    <TileContainer>
                                        <Tile size={'full'}>
                                            <TileBody>
                                                <Button type="submit" className="float-sm-right" variant="success">
                                                    Sauvegarder
                                                </Button>
                                                {error && <span style={{marginTop: '20px', color: 'red'}}>
                                                    <strong>Erreur!</strong> {error}
                                                </span>}
                                                {message && <span style={{marginTop: '20px', color: 'green'}}>
                                                    {message}
                                                </span>}
                                            </TileBody>
                                        </Tile>
                                    </TileContainer>
                                    
                                </Col>
                            </Row>

                        </Form>

                    </Full>
                ) : null }
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetCluster,
            getClusterById,
            getClusterElementsByCluster,
            resetBlock,
            getBlockAll
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        cluster:state.cluster.cluster,
        clusterElements:state.cluster.clusterElements,
        blocks: state.block.blocks,
        error: state.block.error,
		message: state.block.message
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Cluster);