import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
import history from '../../../../history';
import moment from 'moment';

import { resetSession, getSessionAll/*, addProject, deleteProject*/ } from '../../actions/session'

//import { Panel } from 'modules/ts-strap/components/form';
import FullCenter from './../../../../modules/ts-strap/components/partials/FullCenter';

import { ComplexAll } from './../../../../modules/ts-strap/components/complex'

// import { modalFunction } from './../../../../modules/ts-strap/components/partials';

//import { required } from '../../lib/validate';

class Sessions extends Component {

    componentDidMount(){
    }

    renderTd = (e) => {
        return {
            id: e._id,
            key: "sessions_"+e._id,
            application: e.game.name,
            hardware_type: e.hardwareType,
            hardware_id: e.hardwareId,
            date: moment(e.start).format("DD/MM/YYYY HH:mm"),
            name: e.name
        }
    }
    render(){

        const { session, menu, match /*addProject, deleteProject*/ } = this.props;

        const properties = [
            { short: "id", width: "5%", category: "id"},
            { short: "application", name: "Application", width: "20%", category: "classic"},
            { short: "hardware_type", name: "Matériel", width: "25%", category: "classic"},
            { short: "hardware_id", name: "Identifiant du matériel", width: "25%", category: "classic"},
            { short: "date", name: "Date", width: "15%", category: "classic"},
            { short: "actions", name: "Actions", width: "10%", category: "actions" }
        ];

        const filters = [
            { type:"search", size:100, name:"searchSessionByName", placeholder:"Recherche par nom" },
            { type:"hidden", name:"sortGameId", value:""+match.params.id+"" }
        ]

        const portlet = {
            title: "Sessions",
            links: [
                { name: "Recherche", path: "/sessions" }
            ]
        }
            
        return(
            <React.Fragment>
                <div id="popup"></div>
                <MenuLeft menu={menu} />
                <HeaderTop menu={menu} />
                <FullCenter portlet={portlet}>
                    <ComplexAll
                        getElementsAll={getSessionAll}
                        resetElements={resetSession}
                        elements={session.sessions}
                        properties={properties}
                        filters={filters}
                        renderTd={this.renderTd}
                        pending={session.pending}
                        noDataMessage = {"Pas de sessions"}
                        // deleteAction = {(e) => {
                        //     modalFunction({
                        //         btnFunction:() => {
                        //             deleteProject(e)
                        //         },
                        //         btnText:"Confirmer",
                        //         text:"Êtes-vous sûr ?",
                        //         header:"Supprimer une session",
                        //         state:"session.pendingDelete"
                        //     }, "popup")
                        // }}
                        editAction = {(id) => ( history.push("/"+match.params.id+"/session/"+id) )}
                    />
                </FullCenter>
            </React.Fragment>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetSession,
            getSessionAll
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        session: state.session,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Sessions);
