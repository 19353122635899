import { getData } from './index';
import {
    CLUSTER_ALL,
    CLUSTER_ID,
    CLUSTER_ELEMENTS,
    CLUSTER_RESET,
    CLUSTER_ERROR
} from './types';

import { dataToUrl } from './../../../modules/stam-strap/utils'

export function getClusterAll(data) {
    const url = '/cluster/all'+dataToUrl(data);
    return dispatch => getData(CLUSTER_ALL, null, CLUSTER_ERROR, true, url, dispatch);
}

export function getClusterById(id){
    const url = '/cluster/id/'+id;
    return dispatch => getData(CLUSTER_ID, null, CLUSTER_ERROR, true, url, dispatch);
}

export function getClusterElementsByCluster(id){
    const url = '/cluster/id/'+id+'/elements';
    return dispatch => getData(CLUSTER_ELEMENTS, null, CLUSTER_ERROR, true, url, dispatch);
}

export function resetCluster() {
	return dispatch => dispatch({ type: CLUSTER_RESET });
}