import history from '../history';
import store from 'index'
import { generateTranslationPopupNew, generateTranslationPopupUpdate } from 'modules/ts-stuva/forms'

const tsstrap = {
    translationEdit:(e) => {
        history.push("/"+store.getState().menu.gameId+"/translation/"+e+"")
    },
	translationNewPopup:(origin) => {
		let defaultVars = {
			game : [{
				_id:store.getState().menu.game._id,
				name:store.getState().menu.game.name,
			}]
		};

		generateTranslationPopupNew(origin, defaultVars)
	},
	translationEditPopup:(origin) => {
		generateTranslationPopupUpdate(origin)
	},
	goToModelId:(model, id) => {
		history.push("/"+store.getState().menu.gameId+"/"+model+"/"+id+"")
	}
}

export default tsstrap;