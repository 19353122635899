//ts-stuva
import { tsstuva } from './../../../settings'

export default function generateInitialValue(model, element) {

    let initialValues = {};

    for(let [short, value] of Object.entries(model)){
        if(value["update"]){
            //console.log()
            switch (value.type) {
                case 'select':
                    if(element.hasOwnProperty(short)){
                        if(value.data.isMulti){
                            initialValues[short] = [];
                            for(let e of element[short]){
                                //console.log(value)
                                initialValues[short].push(tsstuva.actions[value.modelType].display.selectDefault(e))
                            }
                        }else{
                            if(value.data.hasOwnProperty("options")){
                                initialValues[short] = element[short];
                            }else{
                                if(element[short]){
                                    initialValues[short] = tsstuva.actions[value.modelType].display.selectDefault(element[short]);
                                }else{

                                }
                            }
                        }
                    }
                    break;
                case 'datatableedit':
                    if(element.hasOwnProperty(short)){
                        //console.log(short, element[short])
                        initialValues[short] = element[short]
                        for(let i=0; i<element[short].length; i++){
                            for (const v in element[short][i]) {
                                initialValues[short+"_"+i+"_"+v] = element[short][i][v];
                            }
                        }
						if(element.hasOwnProperty("previousupdate")){
							if(element.previousupdate.hasOwnProperty(short)){
								initialValues[short] = element.previousupdate[short]
								for(let i=0; i<element.previousupdate[short].length; i++){
									for (const v in element.previousupdate[short][i]) {
										initialValues[short+"_"+i+"_"+v] = element.previousupdate[short][i][v];
									}
								}
							}
						}
                    }
                    break;
                case 'translationtext':
                    if(element.hasOwnProperty(short)){
                        initialValues[short] = (element[short] ? element[short]._id : null)
                    }
                    break;
                case 'checkbox':
                    if(element.hasOwnProperty(short)){
                        initialValues[short] = (element[short] ? true : false)
                    }
                    break;
                case 'location':
                    if(element.hasOwnProperty(short)){
						initialValues[short] = element[short]
						if(element.hasOwnProperty("previousupdate")){
							if(element.previousupdate.hasOwnProperty(short)){
								initialValues[short] = element.previousupdate[short]
							}
						}
                    }
                    break;
				case 'textarea':
                case 'text':
					if(element.hasOwnProperty(short)){
                        initialValues[short] = element[short]
						if(element.hasOwnProperty("previousupdate")){
							if(element.previousupdate.hasOwnProperty(short)){
								initialValues[short] = element.previousupdate[short]
							}else{
								initialValues[short] = ""
							}
						}
                    }
					break;
                default:
            }
        }
    }

    return initialValues;
}