//Redux
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//stam-strap
import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
//child-app
import history from '../../../../history';
//ts-strap
import { Full, FullCenter, TileContainer, Tile, TileHeader, TileBody, Cover, modalFunction } from './../../../../modules/ts-strap/components/partials';
import { Form, Panel, PanelButton } from './../../../../modules/ts-strap/components/form';
import { processSubmit, checkNull } from './../../../../modules/ts-strap/lib';
//various
import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import { faPencilRuler } from '@fortawesome/free-solid-svg-icons'
//import QRCode from 'qrcode'
//ts-stuva
import { generateInitialValue } from './../../../../modules/ts-stuva/forms'
import { PanelNew } from './../../../../modules/ts-stuva/components'
import { actions } from '../../stuva';
const elementActions = actions["fqrcode"];
const reset = elementActions.reset;
const getById = elementActions.getById;
const updateById = elementActions.updateById;
const trashById = elementActions.trashById;

class Fqrcode extends Component {
    componentDidMount() {
        const { match, reset, getById } = this.props;
        const id = match && match.params && match.params.idfqrcode;
        reset();
        if (id) {
            getById(id);
        }
    }

    componentDidUpdate() {
        //console.log("didUpdate");
    }

    submitEdit(values) {
        const { match, updateById, fqrcode } = this.props;
        let element = fqrcode;
        const id = match && match.params && match.params.idfqrcode;
        console.log(processSubmit(values));
        if (element && !element.pendingUpdate) {
            updateById(id, processSubmit(values));
        }
    }

	displayQrcodeParams(element){
		return <React.Fragment>
			<Form
				initialValues={{
					urlfinal: element.urlfinal,
					urlsuffix: element.urlsuffix
				}}
			>
				<Panel
					width={[3, 9]}
					inputs={[
						{ short: "urlfinal", name: "Url", type: "text", placeholder: "", disabled: true },
						{ short: "urlsuffix", name: "Suffixe", type: "text", placeholder: "", disabled: true }
					]}
				/>
			</Form>
		</React.Fragment>
	}

	displayQrcode(data){
		/*QRCode.toDataURL('I am a pony!')
			.then(url => {
			console.log(url)
			})*/
		return <React.Fragment>
			<img alt="" src={"data:image/svg+xml;base64,"+btoa(data.svg)} />
		</React.Fragment>
	}

    render() {
        const { match, fqrcode, trashById, menu } = this.props;
        const id = match && match.params && match.params.id;
        const idfqrcode = match && match.params && match.params.idfqrcode;

        const element = fqrcode;

        let portlet = {
            title: "QR Codes",
            links: [
                /*{name:"Sessions", path:"/"+id+"/sessions"},*/
                { name: "QR Code", path: "/" + id + "/fqrcode/" + idfqrcode }
            ]
        }

        return (
            <React.Fragment>
                <div id="popup"></div>
                <MenuLeft menu={menu} />
                <HeaderTop menu={menu} />

                {element && !element.pending ? (

                    <FullCenter portlet={portlet}>
                        <TileContainer>
                            <Tile size={"full"}>
                                <TileBody>
                                    <Cover
                                        icon={faPencilRuler}
                                        title={element.name}
                                        subtitle={checkNull(element.short)}
                                    >
                                        Qr code créé le {moment().format("DD/MM/YYYY")}<br />
                                        Dernière modification le {moment().format("DD/MM/YYYY")}
                                    </Cover>
                                </TileBody>
                            </Tile>
                        </TileContainer>

                        <Row>
                            <Col lg="6" className="tile-col">

                                {/* Général */}
                                <TileContainer>
                                    <Tile size={"full"}>
                                        <TileHeader title="Général" border={true} />
                                        <TileBody>

                                            <Form
                                                onSubmit={values => this.submitEdit(values)}
                                                initialValues={generateInitialValue(elementActions.model, element)}
                                            >

                                                <PanelNew
                                                    width={[3, 9]}
                                                    model={elementActions.model}
                                                    filter={"update"}
                                                    element={element}
                                                />

                                                <PanelButton
                                                    pending={element.pendingUpdate}
                                                    message={element.message}
                                                />

                                            </Form>
                                        </TileBody>
                                    </Tile>
                                </TileContainer>

                                {/* Delete */}
                                <TileContainer>
                                    <Tile size={'full'}>
                                        <TileBody>
                                            <Button
                                                onClick={function () {
                                                    modalFunction({
                                                        btnFunction: () => {
                                                            trashById(idfqrcode).then(
                                                                function (response) {
                                                                    history.push("/" + id + "/fvalues")
                                                                }
                                                            );
                                                        },
                                                        btnText: "Confirmer",
                                                        text: "Êtes-vous sûr de supprimer cette valeur ?",
                                                        header: "Supprimer une valeur",
                                                        state: "fqrcode.pendingTrash"
                                                    }, "popup")
                                                }}
                                                className="float-sm-right"
                                                variant="danger"
                                            >
                                                Supprimer ce QR Code
											</Button>
                                        </TileBody>
                                    </Tile>
                                </TileContainer>
                            </Col>

                            <Col lg="6" className="tile-col">

								{/* Parameters */}
								<TileContainer>
									<Tile size={"full"}>
										<TileHeader title="Paramètres" border={true} />
										<TileBody>
											{this.displayQrcodeParams(element)}
										</TileBody>
									</Tile>
								</TileContainer>

								{/* QrCode */}
								<TileContainer>
									<Tile size={"full"}>
										<TileHeader title="Qrcode" border={true} />
										<TileBody>
											{this.displayQrcode(element.qrcode)}
										</TileBody>
									</Tile>
								</TileContainer>
                            </Col>

                        </Row>

                    </FullCenter>
                ) : (
                        <Full>
                            Chargement
                        </Full>
                    )}
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            reset,
            getById,
            updateById,
            trashById
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        fqrcode: state.fqrcode.fqrcode,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Fqrcode);