// react
import React, { useState, useEffect } from "react";

// extern
import ApexChart from 'apexcharts';

const MultiSeriesChart = (props) => {

    const { data } = props;

    const [multiSeriesChart, setMultiSeriesChart] = useState(null);

    useEffect(() => {

        const options = {
            chart: {
                height: 650,
                type: 'rangeBar'
            },
            states: {
                hover: {
                    filter: {
                        type: 'none',
                    }
                },
            },
            plotOptions: {
                bar: {
                    horizontal: true
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: 'vertical',
                    shadeIntensity: 0.25,
                    gradientToColors: undefined,
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [50, 0, 100, 100]
                }
            },
            xaxis: {
                type: 'datetime'
            },
            legend: {
                position: 'top'
            }
        };
    
        const series = [];

        data.forEach(myData => {

            series.push({
                name: myData.user.login,
                data: []
            });

            myData.events.forEach(event => {
                series[series.length-1].data.push({
                    x: event.action,
                    y: [
                        new Date(event.time).getTime(),
                        new Date(event.time).getTime() + 10 * 1000
                    ]
                })
            });
            
        });        

        setMultiSeriesChart({
            options: options,
            series: series
        })

    }, []);

	return (
		<React.Fragment>
            {multiSeriesChart &&
                <ApexChart
                    options={multiSeriesChart.options}
                    series={multiSeriesChart.series}
                    type='rangeBar'
                    width='100%'
                    className='multiSeriesChart'
                />
            }
		</React.Fragment>
	);
};

export default MultiSeriesChart;