import { getData } from './index';
import {
    SESSION_RESET,
    SESSION_ALL,
    SESSION_ID,
    SESSION_EVENTS,
    SESSION_ERROR
} from './types';

import { dataToUrl } from './../../../modules/stam-strap/utils'

export function resetSession() {
    return dispatch => dispatch({ type: SESSION_RESET });
}

export function getSessionAll(data) {
    const url = '/session/all'+dataToUrl(data);
    return dispatch => getData(SESSION_ALL, null, SESSION_ERROR, true, url, dispatch);
}

export function getSessionById(id){
    const url = '/session/id/'+id;
    return dispatch => getData(SESSION_ID, null, SESSION_ERROR, true, url, dispatch);
}

export function getEventsBySession(id){
    const url = '/event/session/'+id;
    return dispatch => getData(SESSION_EVENTS, null, SESSION_ERROR, true, url, dispatch);
}