import { getData, postData } from './index';
import {
    USER_ERROR,
    //RESET_USER,
    UPDATE_NOTIFICATION,
    GET_USER,
    GET_USER_SELF,
    SET_PREFERENCE,
    USER_RESET,
    USER_ALL,
    //USER_ALL_PENDING
} from './types';

import { dataToUrl } from './../../../modules/stam-strap/utils'
 
export function getUserAll(data){
    const url = '/user/all'+dataToUrl(data);
    return dispatch => getData(USER_ALL, null, USER_ERROR, true, url, dispatch)
}

export function updateNotification(bool, id, preference) {
    const url = '/user/notification/' + id;
    return dispatch => {
        dispatch({
            type: SET_PREFERENCE,
            preference,
            value: bool
        });
        return postData(UPDATE_NOTIFICATION, null, USER_ERROR, true, url, dispatch, {
            notification: bool,
            preference: preference
        });
    };
}

export function resetUser() {
    return dispatch =>
        dispatch({
            type: USER_RESET
        });
}

export function getUserSelf() {
    const url = '/user/self';
    return dispatch => getData(GET_USER_SELF, null, USER_ERROR, true, url, dispatch);
}

export function getUser(userId) {
    const url = '/user/specific/' + userId;
    return dispatch => getData(GET_USER, null, USER_ERROR, true, url, dispatch);
}

/*

export function editUserRequest(userId, payload, redirect) {
    const url = '/user/edit/' + userId;
    return dispatch => {
        dispatch({ type: EDIT_USER_REQUEST, userId, payload });

        return putData(
            EDIT_USER_SUCCESS,
            EDIT_USER_ERROR,
            true,
            url,
            dispatch,
            payload
        ).then(r => {
            if (r.response && redirect) history.push(redirect);
        });
    };
}

*/