import React from 'react';
//ts-strap
import { Panel } from './../../../modules/ts-strap/components/form';
import { returnFunction } from './../../../modules/ts-strap/lib';

import { actions } from '../../../modules/stam-strap/stuva/index.js';
const elementActions = actions["translation"];

export default function generatePanelNew(width, model, filter) {

    let inputs = [];

    for(let [short, value] of Object.entries(model)){
        if(value[filter]){
            let input = {
                short:short,
                name:value.name,
                type:value.type
            };

            if(value.hasOwnProperty("placeholder")){
                input.placeholder = value.placeholder;
            }

            if(value.hasOwnProperty("data")){
                input.data = value.data;
            }

            if(value.type === "translationtext"){
                if(!value.hasOwnProperty("data")){
                    input.data = {};
                }

                input.data.loadOptions = returnFunction(elementActions.getAll, "searchTest", 20, "translation.translations", "_id", "short");
            }

            inputs.push(input)
        }
    }

    return (
        <Panel
            width={width}
            inputs={inputs}
        />
    );
}