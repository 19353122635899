//Redux
import React, { Component } from 'react';

import { AppStore } from './../../modules/stam-strap/components';

import './publicAppStore.css';

import LatLon from 'geodesy/latlon-ellipsoidal-vincenty.js';

import parisTourCubeRectangle from './../../css/assets/towers/PARIS_TOUR_CUBE_RECTANGLE.png';
import berlinTourCubeRectangle from './../../css/assets/towers/BERLIN_TOUR_CUBE_RECTANGLE.png';
import rotterdamTourCubeRectangle from './../../css/assets/towers/ROTTERDAM_TOUR_CUBE_RECTANGLE.png';
import chicagoTourCubeRectangle from './../../css/assets/towers/CHICAGO_TOUR_CUBE_RECTANGLE.png';
import parisTourAlpha from './../../css/assets/towers/PARIS_TOUR_ALPHA.png';
import berlinTourAlpha from './../../css/assets/towers/BERLIN_TOUR_ALPHA.png';
import rotterdamTourAlpha from './../../css/assets/towers/ROTTERDAM_TOUR_ALPHA.png';
import chicagoTourAlpha from './../../css/assets/towers/CHICAGO_TOUR_ALPHA.png';

const towerPosition = [
	{ id: 'montparnasse', lat: 48.84228969124986, lon: 2.321929940359478 },
	{ id: 'berlin', lat: 52.52096122195783, lon: 13.409378104537675 },
	{ id: 'chicago', lat: 42.349499262181, lon: -71.07511537262594 },
	{ id: 'rotterdam', lat: 51.905571652809705, lon: 4.466621814329858 },
];

const trads = [
	{
		id: 'en',
		welcomeTo: 'Welcome!',
		choose: 'Choose your city observation deck'
	},
	{
		id: 'fr',
		welcomeTo: 'Bienvenue !',
		choose: 'Choisissez votre point d\'observation'
	},
	{
		id: 'de',
		welcomeTo: 'Willkommen !',
		choose: 'Wählen Sie Ihren Standort'
	},
	{
		id: 'nl',
		welcomeTo: 'Welkom !',
		choose: 'Kies je observatiepunt'
	},
	{
		id: 'es',
		welcomeTo: 'Bienvenido !',
		choose: 'Elige tu punto de observación'
	},
];

const menuTowers = [
	{
		id: 1,
		available: true,
		town: 'paris',
		name: 'montparnasse',
		clean: 'montparnasse',
		image: parisTourCubeRectangle,
		imageTower: parisTourAlpha,
		color: '#9ABDDB',
		onclick: () => {
			window.location.replace('https://app.tourmontparnasse56.com');
		},
	},
	{
		id: 2,
		available: true,
		town: 'berlin',
		name: 'fernsehturm',
		clean: 'berlin',
		image: berlinTourCubeRectangle,
		imageTower: berlinTourAlpha,
		color: '#F30C69',
		onclick: () => {
			window.location.replace('https://app.tv-turm.de');
		},
	},
	{
		id: 3,
		available: true,
		town: 'rotterdam',
		name: 'euromast',
		clean: 'rotterdam',
		image: rotterdamTourCubeRectangle,
		imageTower: rotterdamTourAlpha,
		color: '#F4C057',
		onclick: () => {
			window.location.replace('https://app.euromast.nl');
		},
	},
	{
		id: 4,
		available: true,
		town: 'chicago',
		name: 'chicago 360',
		clean: 'chicago',
		image: chicagoTourCubeRectangle,
		imageTower: chicagoTourAlpha,
		color: '#1DD761',
		onclick: () => {
			window.location.replace('https://app.360chicago.com');
		},
	},
];

const Tower = ({ tower, i }) => {
	return (
		<div
			key={i}
			onClick={() =>
				tower.onclick()
			}
			style={{
				position: 'relative',
				width: '100%',
				margin: '0 10px 0 10px',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			{/* isLoading == i && <ActivityIndicator size={'large'}style={styles.loader}/> */}
			<div
				style={{
					color: tower.color,
					fontFamily: 'Poppins-ExtraBold',
					justifyContent: 'center',
					textAlign: 'center',
					textTransform: 'uppercase',
					fontSize: 24,
				}}
			>
				{tower.town}
			</div>
			<div
				style={{
					color: '#000000',
					paddingHorizontal: 5,
					paddingVertical: 5,
					width: 150,
					textAlign: 'center',
					alignSelf: 'center',
					backgroundColor: tower.color,
				}}
			>
				<div
					style={{
						color: '#000000',
						textAlign: 'center',
						fontFamily: 'Poppins-ExtraBold',
						textTransform: 'uppercase',
						fontSize: 14,
					}}
				>
					{tower.name}
				</div>
			</div>
			<div
				style={{
					height: '100%',
					width: '100%',
					flex: 1,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<div
					style={{
						margin: '0',
						height: '100%',
						aspectRatio: '1125/2436',
						backgroundImage: `url(${tower.image})`,
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
						backgroundSize: '150% auto',
					}}
				></div>
			</div>
		</div>
	);
};
class PublicAppStore extends Component {
	constructor(props) {
		super(props);
		this.state = {
			lang: 'en',
		};
	}

	componentDidMount() {
		let lang = navigator.language.substr(0, 2);

		if (
			lang === 'fr' ||
			lang ===
				'en' /* || lang === 'es' || lang === 'de' || lang === 'nl'*/
		) {
			this.setState({ lang: String(lang) });
		}

		navigator.geolocation.getCurrentPosition((pos) => {
			let pos1 = new LatLon(pos.coords.latitude, pos.coords.longitude);

			for (let tpos of towerPosition) {
				let pos2 = new LatLon(tpos.lat, tpos.lon);

				let d = pos1.distanceTo(pos2);

				if (d < 5000) {
					console.log('distance to tower : ', d, tpos.id);

					switch (tpos.id) {
						case 'montparnasse':
							window.location.replace(
								'https://app.tourmontparnasse56.com'
							);
							break;
						case 'berlin':
							window.location.replace('https://app.tv-turm.de');
							break;
						case 'chicago':
							window.location.replace(
								'https://app.360chicago.com'
							);
							break;
						case 'rotterdam':
							window.location.replace('https://app.euromast.nl');
							break;
					}
				}
			}
		});
	}

	render() {
		return (
			<div
				style={{
					position: 'absolute',
					width: '100%',
					height: '100%',
					backgroundColor: '#080B18',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div
					style={{
						position: 'absolute',
						width: 'calc(100% - 48px)',
						height: '70%',
						margin: '0px 24px 0px 24px',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<div
						id='magnicity-welcome'
						className='magnicity-welcome'
					>
						<div className='magnicity-welcome-text'>
							{String(
								trads.find(
									(t) => t.id === String(this.state.lang)
								).welcomeTo
							)}
						</div>
						<div className='introScreen-langSelectionTitle'>{String(trads.find((x) => x.id === String(this.state.lang)).choose)}</div>
						{/* <div
							className='magnicity-welcome-place'
							style={{
								color: towerColor,
							}}
						>
							{place === 'montparnasse' ? 'paris' : place}
						</div>
						<div
							className='magnicity-welcome-towerName'
							style={{
								backgroundColor: towerColor,
							}}
						>
							<div
								className='magnicity-welcome-towerNameContent'
								style={{
									backgroundColor: towerColor,
								}}
							>
								{towerName}
							</div>
						</div> */}
					</div>
					<div
						style={{
							width: 'min(100% - 48px, 800px)',
							height: '50%',
							margin: '0',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						{menuTowers.slice(0, 2).map((tower, i) => (
							<Tower
								tower={tower}
								i={i}
								key={i}
							/>
						))}
					</div>
					<div
						style={{
							width: 'min(100% - 48px, 800px)',
							height: '50%',
							margin: '0',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						{menuTowers.slice(2).map((tower, i) => (
							<Tower
								tower={tower}
								i={i}
								key={i}
							/>
						))}
					</div>
				</div>

				{/* <div className='towers-title'>
					{String(
						trads.find((t) => t.id === String(this.state.lang))
							.whereGo
					)}
				</div>

				<div className='tower-explorerImgContainer'>
					<div className='tower-explorerImg'>
						<div
							className='tower-explorerImg-Part clickable '
							onClick={() => {
								window.location.replace(
									'https://app.360chicago.com'
								);
							}}
						></div>
						<div
							className='tower-explorerImg-Part clickable '
							onClick={() => {
								window.location.replace(
									'https://app.tourmontparnasse56.com'
								);
							}}
						></div>
						<div
							className='tower-explorerImg-Part clickable '
							onClick={() => {
								window.location.replace(
									'https://app.euromast.nl'
								);
							}}
						></div>
						<div
							className='tower-explorerImg-Part clickable '
							onClick={() => {
								window.location.replace(
									'https://app.tv-turm.de'
								);
							}}
						></div>
					</div>
				</div> */}
			</div>
		);
	}
}

export default PublicAppStore;
