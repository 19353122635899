import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
import { Full, TileContainer, Tile, TileHeader, TileBody } from './../../../../modules/ts-strap/components/partials';
import history from '../../../../history';

import moment from 'moment';

import { Form, Panel, PanelButton } from './../../../../modules/ts-strap/components/form';
import { Row, Col, Button } from 'react-bootstrap';
import { Cover } from './../../../../modules/ts-strap/components/partials';
import { faPencilRuler } from '@fortawesome/free-solid-svg-icons'

import { processSubmit, checkNull } from './../../../../modules/ts-strap/lib';
import { modalFunction } from './../../../../modules/ts-strap/components/partials';

// import { getProjectById, resetProjects, updateProjectById, deleteProject } from '../../actions/projects';
import { actions } from '../../stuva';
const elementActions = actions["game"];
const reset = elementActions.reset;
const getById = elementActions.getById;
const updateById = elementActions.updateById;
const deleteById = elementActions.deleteById;

export const REACT_APP_PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;

class Game extends Component {
    componentDidMount() {
        const { match, reset, getById/*, getProjectById, resetProjects*/ } = this.props;
        const id = match && match.params && match.params.id;
        reset();
        if (id) {
            getById(match.params.id);
        }
    }

    componentDidUpdate(){
        //console.log("didUpdate");
    }

    submitEdit(values) {
    //     const { match, updateProjectById, project } = this.props;
    //     const id = match && match.params && match.params.id;
    //     //console.log(processSubmit(values));
    //     if (project && !project.pendingUpdate) {
    //         updateProjectById(id, processSubmit(values));
    //     }
    }

    render() {
        const { match, game, deleteById, menu } = this.props;
        const id = match && match.params && match.params.id;

        const element = game;
        
        let portlet = {
            title:"Applications",
            links:[
                /*{name:"Sessions", path:"/"+id+"/sessions"},*/
                {name:"Application", path:"/"+id+"/block/"+id}
            ]
        }

        return (
            <React.Fragment>
                <div id="popup"></div>
                <MenuLeft menu={menu} />
                <HeaderTop menu={menu} />

                {element && !element.pending ? (

                    <Full portlet={portlet}>
                        <TileContainer>
                            <Tile size={"full"}>
                                <TileBody>
                                    <Cover
                                        icon={faPencilRuler}
                                        title={element.name}
                                        subtitle={checkNull(element.short)}
                                    >
                                        Application créée le {moment().format("DD/MM/YYYY")}<br />
                                        Dernière modification le {moment().format("DD/MM/YYYY")}
                                    </Cover>
                                </TileBody>
                            </Tile>
                        </TileContainer>

                        <Row>
                            <Col lg="6" className="tile-col">

                                {/* Général */}
                                <TileContainer>
                                    <Tile size={"full"}>
                                        <TileHeader title="Général" border={true} />
                                        <TileBody>

                                            <Form
                                                onSubmit={values => this.submitEdit(values)}
                                                initialValues={{
                                                    name:(element.name) || "",
                                                    short:(element.short) || ""
                                                }}
                                            >

                                                <Panel
                                                    width={[3, 9]}
                                                    inputs={[
                                                        { short: "name", name: "Nom", type: "text", placeholder: "Nom de l'application" },
                                                        { short: "short", name: "Short", type: "text", placeholder: "Short" }
                                                    ]}
                                                />

                                                <PanelButton
                                                    pending={element.pendingUpdate}
                                                    message={element.message}
                                                />

                                            </Form>
                                        </TileBody>
                                    </Tile>
                                </TileContainer>

                                {/* Delete */}
								<TileContainer>
									<Tile size={'full'}>
										<TileBody>
											<Button
                                                onClick={function(){
                                                    modalFunction({
                                                        btnFunction:() => {
                                                            // deleteById(id).then(
                                                            //     function(response){
                                                            //         history.push("/games")
                                                            //     }
                                                            // );
                                                        },
                                                        btnText:"Confirmer",
                                                        text:"Êtes-vous sûr de supprimer cette application ?",
                                                        header:"Supprimer une application",
                                                        state:"game.pendingDelete"
                                                    }, "popup")
                                                }}
                                                className="float-sm-right"
                                                variant="danger"
                                            >
												Supprimer cette application
											</Button>
										</TileBody>
									</Tile>
								</TileContainer>
                            </Col>

                            <Col lg="6" className="tile-col">

                                {/* Other panels */}

                            </Col>

                        </Row>

                    </Full>
                ) : (
                    <Full>
                        Chargement
                    </Full>
                )}
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            reset,
            getById,
            updateById,
            deleteById
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        game: state.game.game,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Game);