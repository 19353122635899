export const pl = {
    menu:{
        applications:"aplikacje",
        users:"użytkownicy",
        sessions:"sesje"
    },
    translate:{
        translations:"tłumaczenia",
        addTranslation:"Dodaj tłumaczenie",
        searchByShort:"Wyszukiwanie według skrótu"
    }
}