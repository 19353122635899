import React from 'react';
import { GetTranslation } from '../modules/stam-strap/components/partials';

export const mcard = {
    name: "mcard",
	clean:"fiche",
    actions: [
        "all", "id", "add", "update", "trash", "reset"
    ],
    model: {
        game:{
            name:"Application",
            type:"select",
            data:{
                isMulti:true
            },
            modelType:"game",
            update:true,
            add:true
        },
        short: {
            name: "Short",
            type: "text",
            placeholder: "Short",
            add: true,
            update: true
        },
        name: {
            name: "Nom",
            placeholder: "Nom de l'objet",
            type: "translationtext",
            //add: true,
            update: true
        },
        published: {
			name: "Published",
			placeholder: "Published",
            type: "text",
			update: true
		},
		mbuilding:{
            name:"Tour",
            type:"select",
            data:{
                isMulti:false
            },
            modelType:"mbuilding",
            update:true,
            add:true
        },
        description: {
            name: "Description",
            type: "textarea",
            placeholder: "Description",
            update: true
        },
		mstory:{
            name:"Story",
            type:"select",
            data:{
                isMulti:false
            },
            modelType:"mstory",
            update:true
        },
		m3dmodel:{
            name:"3d Model",
            type:"select",
            data:{
                isMulti:false
            },
            modelType:"m3dmodel",
            update:true
        },
		ffile:{
            name:"Media",
            type:"select",
            data:{
                isMulti:false
            },
            modelType:"ffile",
            update:true,
        },
        teaserSummary: { name: "teaserSummary", placeholder: "", type: "translationtext", update: true },
        teaser1Title: { name: "teaser1Title", placeholder: "", type: "translationtext", update: true },
        teaser1Subtitle: { name: "teaser1Subtitle", placeholder: "", type: "translationtext", update: true },
        teaser2Title: { name: "teaser2Title", placeholder: "", type: "translationtext", update: true },
        teaser2Subtitle: { name: "teaser2Subtitle", placeholder: "", type: "translationtext", update: true },
        section1Title: { name: "section1Title", placeholder: "", type: "translationtext", update: true },
        section1Content: { name: "section1Content", placeholder: "", type: "translationtext", update: true },
        section2Title: { name: "section2Title", placeholder: "", type: "translationtext", update: true },
        section2Content: { name: "section2Content", placeholder: "", type: "translationtext", update: true },
        section3Title: { name: "section3Title", placeholder: "", type: "translationtext", update: true },
        section3Content: { name: "section3Content", placeholder: "", type: "translationtext", update: true },
        sectionAddTitle: { name: "sectionAddTitle", placeholder: "", type: "translationtext", update: true },
        sectionAddContent: { name: "sectionAddContent", placeholder: "", type: "translationtext", update: true },
        hashtag: { name: "hashtag", placeholder: "", type: "translationtext", update: true }
    },
    filters:{
        default:{
            filter:"searchMcardByShort",
            quantity:20,
            state:"mcard.mcards",
            value:"_id",
            label:"short"
        },
		all:[
			{
				type:"search",
				size:25,
				name:"searchMcardByShort",
				placeholder:"Recherche par short"
			},
            {
				type:"select",
				size:50,
				name:"searchMcardByPublished",
				placeholder:"",
				options:[
					{
						value: 'active',
						label: 'Active'
					},
					{
						value: 'draft',
						label: 'Draft'
					}
				],
				default:"active"
			}
		]
    },
    display:{
        selectDefault:(e) => {
            return {
                label:e.name,
                value:e._id,
                force:true
            }
        }
    },
	properties:[
		{ short: "short", name: "Short", width: "30%", category: "classic"},
		{ short: "mbuilding", name: "Tour", width: "20%", category: "classic"},
        { short: "published", name: "Publié", width: "20%", category: "classic"},
		{ short: "name", name: "Nom", width: "20%", category: "classic"},
		{ short: "actions", name: "Actions", width: "10%", category: "actions" }
	],
	renderTd:(e) => {
		return {
			id: e._id,
			key: "mcard_"+e._id,
			short: e.short,
			mbuilding: e.mbuilding && e.mbuilding.short,
            published: e.published,
			name: <GetTranslation quick={e.name && e.name.translations} />
		}
	}
}