import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
import {FullCenter} from './../../../../modules/ts-strap/components/partials/';
import {TileContainer, Tile, TileHeader, TileBody} from './../../../../modules/ts-strap/components/partials/';

import moment from 'moment';

//import { Form, Input } from 'modules/ts-strap/components/form';
import DataTable from './../../../../modules/ts-strap/components/form/DataTable';
import { Media, Row, Col } from 'react-bootstrap';

import { getSessionById, getEventsBySession } from '../../actions/session';

import { Avatar } from './../../../../modules/ts-strap/components/display/';

// extern
import MultiSeriesChart from './../../utils/charts/timeline/MultiSeriesChart';

class Session extends Component {

    componentDidMount() {
        const { match, getSessionById, getEventsBySession } = this.props;
        const idsession = match && match.params && match.params.idsession;
        if (idsession) {
            getSessionById(match.params.idsession);
            getEventsBySession(match.params.idsession);
        }
    }

    dataTableEventsRender = () => {
        const { session } = this.props;

        const properties = [ // DataTable Headers
            { short: "Date", width: "30%", category: "classic" },
            { short: "Map", width: "20%", category: "classic" },
            { short: "Utilisateur", width: "20%", category: "classic" },
            { short: "Action", width: "30%", category: "classic" }
        ];

        const array = [];

        //Add events history
        if (session && session.events && session.events.length > 0) {
            for (let i = 0; i < session.events.length; i++) {
                const object = { // objects for dataTable
                    date: moment(session.events[i].time).format("DD/MM/YYYY HH:mm:ss"),
                    map: session.events[i].map,
                    utilisateur: session.events[i].user.login,
                    action: session.events[i].action
                };
                object.key = session.events[i]._id;
                object.index = i + 1;
                array.push(object);
            }

        }

        //Sort history
        array.sort(function(b, a){
            return moment(a.date,"DD/MM/YYYY HH:mm:ss")+moment(b.date,"DD/MM/YYYY HH:mm:ss");
        });

        return (
            <DataTable small
                properties={properties}
                collection={array}
                noDataMessage = {`Pas d'actions`}
            />
        )
    }

    render() {
        const { match, session, menu } = this.props;
        const id = match && match.params && match.params.id;
        const idsession = match && match.params && match.params.idsession;

        const usersData = [];
        let stringInfoUser = '';

        if (session && session.events && session.events.length > 0) {

            session.events.forEach(event => {
                const foundUser = usersData.find(userData => userData._id === event.user._id);
                if (!foundUser) {
                    usersData.push({ _id: event.user._id, user: event.user, events: [event] });
                } else {
                    foundUser.events.push(event);
                }
            })

        }

        stringInfoUser = usersData.length.toString() + ' joueur';

        if (usersData.length > 1) {
            stringInfoUser += 's';
        }

        let portlet = {
            title:"Sessions",
            links:[
                /*{name:"Sessions", path:"/"+id+"/sessions"},*/
                {name:"session Immergency", path:"/"+id+"/session/"+idsession}
            ]
        }

        /*if(session){
            portlet.links[1].name = "";
        }*/

        return (
            <React.Fragment>
                <MenuLeft menu={menu} />
                <HeaderTop menu={menu} />
                

                {session ? (

                    <FullCenter portlet={portlet}>
                        <TileContainer>
                            <Tile size={"full"}>
                                <TileBody>
                                    <Row>
                                        <Col sm="6">
                                            <Media>
                                                <Avatar />
                                                <Media.Body>
                                                    <h3>Session {session.game.name}</h3>
                                                    <p className="my-0">{session.hardware}</p>
                                                </Media.Body>
                                            </Media>
                                        </Col>
                                        <Col sm="6" className="text-right">
                                            Session commencée le {moment(session.start).format("DD/MM/YYYY HH:mm")}
                                            <br />
                                            {stringInfoUser}
                                        </Col>
                                    </Row>
                                </TileBody>
                            </Tile>
                        </TileContainer>

                        <TileContainer>
                            <Tile size={"full"}>
                                <TileHeader title="Actions" border={true} />
                                <TileBody>
                                    {usersData && usersData.length > 0 &&
                                        <React.Fragment>
                                            <MultiSeriesChart
                                                data={usersData}
                                            />
                                            <br />
                                            <br />
                                            {this.dataTableEventsRender()}
                                        </React.Fragment>
                                    }
                                </TileBody>
                            </Tile>
                        </TileContainer>
                        
                    </FullCenter>
                ) : null }
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getSessionById,
            getEventsBySession
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        session: state.session.session
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Session);