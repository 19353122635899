import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { loginUser, clearLoginError } from '../../actions/auth';
import { Form, Input, Checkbox } from './../../../../modules/ts-strap/components/form';
import { validateEmail, required } from '../../lib/validate';
import history from '../../../../history';
import HeaderTop from '../partials/HeaderTop';
import FullCard from './../../../../modules/ts-strap/components/partials/FullCard';

class Login extends Component {
    componentDidMount() {
        if (this.props.errorAuth){
            this.props.clearLoginError();
        }
        if (this.props.authenticated && this.props.currentUser){
            history.push('/games');
        }
    }
    
    submit = values => {
        this.props.loginUser(values);
    };
    
    render() {
        return (
            <React.Fragment>
                <HeaderTop />
                <FullCard title="Connexion">
                    <Form onSubmit={this.submit} className="w-100">
                        <Input
                            name="email"
                            type="email"
                            label="Votre Email"
                            placeholder="exemple@mail.com"
                            validate={validateEmail()}
                        />
                        <Input
                            name="password"
                            type="password"
                            label="Mot de passe"
                            placeholder="Mot de passe"
                            validate={required()}
                        />
                        <Link to="/forgot-password" id="blue-link" className="form-text">
                            Mot de passe oublié ?
                        </Link>
                        
                        <div className="form">
                            <div className="checkbox-list">
                                <Checkbox label="Rester connecté" name="rememberme" />
                            </div>
                        </div>
                        
                        {this.props.errorAuth ? (
                            <p className="warn center">{this.props.errorAuth}</p>
                        ) : null}
                        
                        <div className="d-table m-auto py-4 text-center">
                            <Button variant="primary" size="lg" type="submit" className="">Je me connecte</Button>
                        </div>
                        
                        {/*<div className="signup">
                            <span style={{ color: '#B0BAC9' }}>
                                Pas encore de compte ?
                            </span>{' '}
                            
                            <Link to="/register" className="signup-link">
                                S’inscrire
                            </Link>
                        </div>*/}
                    </Form>
                </FullCard>
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loginUser, clearLoginError }, dispatch);
}

function mapStateToProps(state) {
  return {
    errorAuth: state.auth.error,
    authenticated: state.auth.authenticated,
    currentUser: state.auth.user
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
