//Redux
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//stam-strap
import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
//import { required } from '../../lib/validate';
//child-app
import history from '../../../../history';
//ts-strap
import { ComplexAll } from 'modules/ts-strap/components/complex'
import { FullCenter, modalFunction } from 'modules/ts-strap/components/partials';
import { processSubmit } from 'modules/ts-strap/lib';
//import { GetTranslation } from '../partials';
//various
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
//ts-stuva
import { /*generatePanelNew,*/ generateInitialValue } from 'modules/ts-stuva/forms'
//import { generatePanelNew } from 'modules/ts-stuva/forms'
import { PanelNew } from 'modules/ts-stuva/components'
import { actions } from '../../stuva';

export default function customs(model, menu){
	//let classes = {};

	//for(let m in models){
	const elementActions = actions[model.name];
	const getAll = elementActions.getAll;
	const add = elementActions.add;
	const trashById = elementActions.trashById;
	const reset = elementActions.reset;
	
	class Elements extends Component {

		UNSAFE_componentWillMount(){
			this.props.reset()
		}

		componentDidMount(){
			
		}

		render(){

			const { element, match, add, trashById, getAll, menuGame } = this.props;

			let filters = []

			if(model.filters && model.filters.all){
				for(let mfa of model.filters.all){
					if(mfa.type === "search"){
						filters.push({
							type:mfa.type,
							size:mfa.size,
							name:mfa.name,
							placeholder:mfa.placeholder
						})
					}else if(mfa.type === "select"){
						let f = {}
						f.type = mfa.type
						f.size = mfa.size
						f.name = mfa.name
						f.placeholder = mfa.placeholder
						f.options = mfa.options
						
						if(mfa.hasOwnProperty("default")){
							f.default = mfa.default
						}

						filters.push(f)
					}
				}
			}			

			//filters.push({ type:"search", size:80, name:"search"+(model.name.charAt(0).toUpperCase()+model.name.slice(1))+"ByShort", placeholder:"Recherche par nom" })

			filters.push({
				type:"button",
				size:25,
				name:"",
				title:"Ajouter un objet",
				icon:faPlusCircle,
				fn:() => {
					modalFunction({
						submit:(values) => {
							let data = processSubmit(values);
							console.log(data);
							if(!element.pendingAdd){
								add(data).then(
									function(response){
										getAll();
										//history.push("/sic/project/"+response.response.data.projects)
									}
								);
							}
						},
						initialValues:generateInitialValue(elementActions.model, {game:[{_id:menuGame._id, name:menuGame.name}]}),
						text:<PanelNew
							width={[3, 9]}
							model={elementActions.model}
							filter={"add"}
						/>,
						header:"Ajouter un fichier",
						state: ""+model.name+".pendingAdd"
					}, "popup")
				}
			})

			const portlet = {
				title:model.clean.charAt(0).toUpperCase()+model.clean.slice(1)+"s",
				links:[
					{name:"Recherche", path:"/"+model.name}
				]
			}
				
			return(
				<React.Fragment>
					<div id="popup"></div>
					<MenuLeft menu={menu} />
					<HeaderTop menu={menu} />
					<FullCenter portlet={portlet}>
						<ComplexAll
							actions={elementActions}
							elements={element[model.name+"s"]}
							properties={model.properties}
							filters={filters}
							renderTd={model.renderTd}
							pending={element.pending}
							noDataMessage = {"Pas de fichiers"}
							deleteAction = {(e) => {
								modalFunction({
									btnFunction:() => {
										trashById(e).then(
											function(response){
												getAll();
											}
										);
									},
									btnText:"Confirmer",
									text:"Êtes-vous sûr ?",
									header:"Supprimer un fichier",
									state:""+model.name+".pendingTrash"
								}, "popup")
							}}
							editAction = {(id) => ( history.push("/"+match.params.id+"/"+model.name+"/"+id) )}
						/>
					</FullCenter>
				</React.Fragment>
			)
		}

	}

	function mapDispatchToProps(dispatch) {
		return bindActionCreators(
			{
				reset,
				getAll,
				trashById,
				add
			},
			dispatch
		);
	}

	function mapStateToProps(state) {
		return {
			element: state[model.name],
			menuGame: state.menu.game
		};
	}

	return connect(
		mapStateToProps,
		mapDispatchToProps
	)(Elements);
}
