//Redux
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//stam-strap
import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
//child-app
import history from '../../../../history';
//ts-strap
import { Full, FullCenter, TileContainer, Tile, TileHeader, TileBody, Cover, modalFunction } from './../../../../modules/ts-strap/components/partials';
import { Form, PanelButton } from './../../../../modules/ts-strap/components/form';
import { processSubmit, checkNull } from './../../../../modules/ts-strap/lib';
//various
import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import { faPencilRuler } from '@fortawesome/free-solid-svg-icons'
//ts-stuva
import { generateInitialValue } from './../../../../modules/ts-stuva/forms'
import { PanelNew } from './../../../../modules/ts-stuva/components'
import { actions } from '../../stuva';
import { GetTranslation } from '../partials';

export default function custom(model, menu){
	//let classes = {};

	//for(let m in models){
	const elementActions = actions[model.name];
	const reset = elementActions.reset;
	const getById = elementActions.getById;
	const updateById = elementActions.updateById;
	const trashById = elementActions.trashById;
	
	class Element extends Component {

		componentDidMount() {
			const { match, reset, getById/*, getProjectById, resetProjects*/ } = this.props;
			const id = match && match.params && match.params.idelement;
			reset();
			if (id) {
				getById(id);
			}
		}

		componentDidUpdate() {
			//console.log("didUpdate");
		}

		submitEdit(values) {
			const { match, updateById, element } = this.props;
			const id = match && match.params && match.params.idelement;
			console.log(processSubmit(values));
			if (element && !element.pendingUpdate) {
				updateById(id, processSubmit(values));
			}
		}

		render() {
			const { match, element, trashById } = this.props;
			const id = match && match.params && match.params.id;
			const idelement = match && match.params && match.params.idelement;

			let portlet = {
				title: model.clean.charAt(0).toUpperCase()+model.clean.slice(1)+"s",
				links: [
					/*{name:"Sessions", path:"/"+id+"/sessions"},*/
					{ name: model.clean.charAt(0).toUpperCase()+model.clean.slice(1), path: "/" + id + "/"+model.name+"/" + idelement }
				]
			}

			return (
				<React.Fragment>
					<div id="popup"></div>
					<MenuLeft menu={menu} />
					<HeaderTop menu={menu} />

					{element && !element.pending ? (

						<FullCenter portlet={portlet}>
							<TileContainer>
								<Tile size={"full"}>
									<TileBody>
										<Cover
											icon={faPencilRuler}
											title={<GetTranslation quick={element.name && element.name.translations} />}
											subtitle={checkNull(element.short)}
										>
											Objet créée le {moment().format("DD/MM/YYYY")}<br />
											Dernière modification le {moment().format("DD/MM/YYYY")}
										</Cover>
									</TileBody>
								</Tile>
							</TileContainer>

							<Row>
								<Col lg="6" className="tile-col">

									{/* Général */}
									<TileContainer>
										<Tile size={"full"}>
											<TileHeader title="Général" border={true} />
											<TileBody>

												<Form
													onSubmit={values => this.submitEdit(values)}
													initialValues={generateInitialValue(elementActions.model, element)}
												>

													<PanelNew
														width={[3, 9]}
														model={elementActions.model}
														filter={"update"}
														element={element}
														getElement={getById}
														updateElement={updateById}
													/>

													<PanelButton
														pending={element.pendingUpdate}
														message={element.message}
													/>

												</Form>
											</TileBody>
										</Tile>
									</TileContainer>

									{/* Delete */}
									<TileContainer>
										<Tile size={'full'}>
											<TileBody>
												<Button
													onClick={function () {
														modalFunction({
															btnFunction: () => {
																trashById(idelement).then(
																	function (response) {
																		history.push("/" + id + "/"+model.name+"s")
																	}
																);
															},
															btnText: "Confirmer",
															text: "Êtes-vous sûr de supprimer cet objet ?",
															header: "Supprimer un objet",
															state: "thing.pendingTrash"
														}, "popup")
													}}
													className="float-sm-right"
													variant="danger"
												>
													Supprimer cet objet
												</Button>
											</TileBody>
										</Tile>
									</TileContainer>
								</Col>

								<Col lg="6" className="tile-col">

									{/* Other panels */}

								</Col>

							</Row>

						</FullCenter>
					) : (
							<Full>
								Chargement
							</Full>
						)}
				</React.Fragment>
			);
		}

	}

	function mapDispatchToProps(dispatch) {
		return bindActionCreators(
			{
				reset,
				getById,
				updateById,
				trashById
			},
			dispatch
		);
	}

	function mapStateToProps(state) {
		return {
			element: state[model.name][model.name]
		};
	}

	return connect(
		mapStateToProps,
		mapDispatchToProps
	)(Element);
}
