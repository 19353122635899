import { default as stamstrap } from "../../../settings/stam-strap"

let placeholder = {
    name: "placeholder",
    actions: [
        "all", "id", "add", "update", "trash", "reset"
    ],
    model: {
        game:{
            name:"Application",
            type:"select",
            data:{
                isMulti:true
            },
            modelType:"game",
            update:true,
            add:true
        },
        short: {
            name: "Short",
            type: "text",
            placeholder: "Short",
            add: true,
            update: true
        },
        name: {
            name: "Nom",
            placeholder: "Nom de l'objet",
            type: "translationtext",
            //add: true,
            update: true
        },
        description: {
            name: "Description",
            type: "textarea",
            placeholder: "Description",
            update: true
        },
        options: {
            name: "Options",
            type: "text",
            placeholder: "op#option;option",
            update: true
        },
        data: {
        }
    }
}

if(stamstrap.hasOwnProperty("models")){
    if(stamstrap.models.hasOwnProperty("placeholder")){
        if(stamstrap.models.placeholder.hasOwnProperty("add")){
            Object.assign(placeholder.model, stamstrap.models.placeholder.add);
        }
    }
}

export default placeholder;