import React from 'react';
//import { GetTranslation } from '../modules/stam-strap/components/partials';
import { modalFunction } from 'modules/ts-strap/components/partials';

export const mversion = {
    name: "mversion",
	clean:"version",
    actions: [
        "all", "id", "add", "update", "trash", "reset"
    ],
    model: {
        date: {
            name: "Date",
            type: "date"
        },
		mbuilding:{
            name:"Tour",
            type:"select",
            data:{
                isMulti:false
            },
            modelType:"mbuilding"
        },
        size: {
            name: "Size",
            type: "number"
        },
        description: {
            name: "Description",
            type: "textarea",
            placeholder: "Description",
            update: true
        },
    },
    filters:{
        /*default:{
            filter:"searchMstoryByShort",
            quantity:20,
            state:"mstory.mstorys",
            value:"_id",
            label:"short"
        },
		all:[
			{
				type:"search",
				size:25,
				name:"searchMstoryByShort",
				placeholder:"Recherche par short"
			},
		]*/
    },
    display:{
        /*selectDefault:(e) => {
            return {
                label:e.name,
                value:e._id,
                force:true
            }
        }*/
    },
	properties:[
        { short: "date", name: "Date", width: "20%", category: "classic"},
		{ short: "mbuilding", name: "Tour", width: "20%", category: "classic"},
        { short: "size", name: "Taille", width: "20%", category: "classic"},
        { short: "details", name: "Details", width: "30%", category: "classic"},
		{ short: "actions", name: "Actions", width: "10%", category: "actions" }
	],
	renderTd:(e) => {
		return {
			id: e._id,
			key: "mstory_"+e._id,
			date: e.date,
			mbuilding: e.mbuilding && e.mbuilding.short,
			size: e.size && Number(e.size/1000000).toFixed(2)+" Mo",
            details:<div
                    style={{
                        position:"absolute"

                    }}
                    onClick={(event) => {
                        event.stopPropagation();
                        modalFunction({
                            text: (
                                <div>{e.files.map(function(f){
                                    let color = "#FFFFFF"
                                    if(f.size > 10000000){
                                        color = "#FF0000"
                                    }else if(f.size > 2000000){
                                        color = "#FFFF00"
                                    }
                                    return <li key={"listing_"+f._id}>
                                        <span style={{
                                            display:"inline-block",
                                            height:"10px",
                                            width:"10px",
                                            backgroundColor:color,
                                            marginRight:"5px"
                                        }}></span>
                                        {Number(f.size/1000000).toFixed(2)+" Mo - "+f.channel+" - "+f.originalname}
                                    </li>
                                })}</div>
                            ),
                            state: "popup",
                            header: "Liste complète",
                            close: true,
                            closeText: "Fermer",
                            free: true
                        }, "popup")
                    }}
                >{e.files.length} fichiers</div>
		}
	}
}