export const game = {
    name:"game",
    actions:[
        "all", "id", "add", "update", "trash", "reset"
    ],
    model:{
        short:{
            type:"text",
            placeholder:"Short"
        },
        name:{
            type:"text",
            placeholder:"Nom de l'application",
            new:true
        }
    },
    display:{
        selectDefault:(e) => {
            return {
                label:e.name,
                value:e._id,
                force:true
            }
        }
    },
    filters: {
        default: {
            filter: "searchGameByName",
            quantity: 20,
            state: "game.games",
            value: "_id",
            label: "short"
        }
    }
}