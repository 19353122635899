import React, { Component } from 'react';
import { connect } from 'react-redux'
//modules
import { Button, Row, Col } from 'react-bootstrap'
//ts-strap
import { TileHeader, TileBody } from './../../../../modules/ts-strap/components/partials';
import DataTable from './../../../../modules/ts-strap/components/form/DataTable';
import { Form, Input, Select } from './../../../../modules/ts-strap/components/form';
import Pagination from './../../../../modules/ts-strap/components/form/Pagination';
import { Calendar } from './../../../../modules/ts-strap/components/display'
//design
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class ComplexAllMini extends Component {

	static mode = {
		list:"list",
		calendar:"calendar"
	}

    state = {
		mode:"list",
        filter: {

        }
    }

    pageDefault = {
        skip: 0,
        limit: 20,
    }

    page = { // Default paginate configuration
        current: 1,
        skip: this.pageDefault.skip,
        limit: this.pageDefault.limit,
        totalSize: 0,
        go: false
    };

    componentDidMount() {
        const { actions, dispatch, filters } = this.props;
        const { skip, limit } = this.page;

        let { getElementsAll, resetElements } = this.props;

        if (actions !== undefined) {
            getElementsAll = actions.getAll;
            resetElements = actions.reset;
        }

        var stateCopy = Object.assign({}, this.state);
        for (let i = 0; i < filters.length; i++) {
            stateCopy.filter[filters[i].name] = ""
        }
        this.setState(stateCopy);

        const { filter } = this.state;

        this.handleFilterStart();

        dispatch(resetElements());
        dispatch(getElementsAll({ skip, limit, filter }));
    }

    handleFilterStart = () => {
        const { filters } = this.props;

        var stateCopy = Object.assign({}, this.state);

        for (let f of filters) {
            if (f.type === "hidden") {
                stateCopy.filter[f.name] = f.value;
            }
        }

        this.setState(stateCopy);
    }

    handleFilter = e => {
        var stateCopy = Object.assign({}, this.state);
        stateCopy.filter[e.target.name] = e.target.value;
        this.setState(stateCopy);

        this.launchRequest();
    }

    launchRequest = () => {
        const { actions, dispatch } = this.props;
        let { getElementsAll } = this.props;

        if (actions !== undefined) {
            getElementsAll = actions.getAll;
        }

        const { skip, limit } = this.page;
        this.page.go = true;
        dispatch(getElementsAll({ skip, limit, filter: this.state.filter }));
    }

    dataTableRender = () => {
        const { /*elements, renderTd, */ properties, noDataMessage, deleteAction, editAction, pending, collection } = this.props;
        //const { skip } = this.page;
        //const { filter } = this.state;

        //const array = [];

        /*if (elements && elements.length) {
            for (let i = 0; i < elements.length; i++) {
                let object = {};
                Object.assign(object, renderTd(elements[i]));

                object.index = skip + i + 1;
                array.push(object);
            }
        }*/

        return (
            <DataTable small
                noEditGlobal
                properties={properties}
                collection={collection}
                noDataMessage={noDataMessage}
                pending={pending}
                deleteAction={deleteAction}
                editAction={editAction}
            />
        )

    };

    renderSwitch(f, index) {
        //const { width } = this.props;

        switch (f.type) {
            case 'text':
            case 'search':
                return (
                    <Form key={index} className={"w-" + f.size + " my-3 " + ((index !== 0) ? "pl-4" : null)}>
                        <Input
                            type={f.type}
                            name={f.name}
                            placeholder={f.placeholder}
                            onChange={this.handleFilter}
                        ></Input>
                    </Form>
                );
            case 'hidden':
                return (
                    <Form key={index} >
                        <Input
                            type="hidden"
                            name={f.name}
                        ></Input>
                    </Form>
                );
            case 'select':

                let selectFunction = (e) => {
                    var stateCopy = Object.assign({}, this.state);
                    stateCopy.filter[f.name] = e;
                    this.setState(stateCopy);
                    this.launchRequest();
                }

                return (
                    <Form key={index} className={"w-" + f.size + " my-3 " + ((index !== 0) ? "pl-4" : null)}>
                        <Select
                            name={f.name}
                            isClearable={true}
                            options={f.options}
                            placeholder={f.placeholder}
                            onChange={selectFunction}
                        />
                    </Form>
                );
            case 'button':
                return (
                    <Form key={index} className={"w-" + f.size + " my-3 " + ((index !== 0) ? "pl-4" : null)}>
                        <div className="form-group">
                            <Button onClick={f.fn} style={{ height: "38px" }} block>
                                <FontAwesomeIcon icon={f.icon} className="mr-2" />
                                {f.title}
                            </Button>
                        </div>
                    </Form>
                );
            default:
                return (null)
        }
    }

    filterRender = () => {
        const { filters } = this.props;

        return (
            <React.Fragment>
                {filters.map((f, index) =>
                    this.renderSwitch(f, index)
                )}
            </React.Fragment>
        )
    }

	setMode = (m) => {
		var stateCopy = Object.assign({}, this.state);
		stateCopy.mode = m;
        this.setState(stateCopy);
	}

    render() {
        const { actions, elements, collection, dispatch, mode, calendarEventFunction, calendarEventRenderFunction } = this.props;
        let { getElementsAll } = this.props;

        if (actions !== undefined) {
            getElementsAll = actions.getAll;
        }

        const { filter } = this.state;

        if (elements && elements['count']) {
            this.page.totalSize = elements['count'];
        }

		//Mode
		let modeSelect = null;
		let modeSelectInitialValues = null;
		let modeCalendar = {}

		if(mode){
			if(mode.length > 1){
				modeSelect = [];
				for(let m of mode){
					if(m === "list"){
						modeSelect.push({ value:"list", label:"Liste"})
					}else if(m === "calendar"){
						modeSelect.push({ value:"calendar", label:"Calendrier"})
						//
						modeCalendar.events = [
							//{ title:"truc", date:"2021-02-07"}
						]
						if(collection){
							for(let c of collection){
								modeCalendar.events.push(calendarEventFunction(c))
							}
						}
					}
				}
				modeSelectInitialValues = {
					mode:modeSelect[0].value
				}
			}else{

			}
		}else{

		}

        return (
            <React.Fragment>

                <TileHeader>
                        {this.filterRender()}
                </TileHeader>
                <TileBody>
					<React.Fragment>
						{ Array.isArray(modeSelect) ? (
							<React.Fragment>
								<Row style={{marginTop:"-35px"}}>
									<Col lg="9">
									</Col>
									<Col lg="3">
										<Form initialValues={modeSelectInitialValues}>
											<Select
												name={"mode"}
												options={modeSelect}
												placeholder={""}
												onChange={this.setMode}
											/>
										</Form>
									</Col>
								</Row>
							</React.Fragment>
						) : null}

						{
							{
								"list":<React.Fragment>
									{this.dataTableRender()}
									{/*(elements)
											? this.dataTableRender()
											: <Spinner />
										*/}

									{this.page.totalSize ? //this.page.totalSize
										<Pagination
											props={{}}
											page={this.page}
											callFunc={(e) => {
												dispatch(getElementsAll({ skip: e.skip, limit: e.limit, filter: filter }))
											}}
										/>
										: null
									}
								</React.Fragment>,
								"calendar":<React.Fragment>
									<Calendar
										events={modeCalendar.events}
										eventRender={calendarEventRenderFunction}
									/>
								</React.Fragment>
							}[this.state.mode]
						}

					</React.Fragment>
                </TileBody>






            </React.Fragment>
        )
    }

}

export default connect()(ComplexAllMini)