import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { TileContainer, Tile, TileHeader, TileBody } from './../../../../modules/ts-strap/components/partials';

//import '@google/model-viewer';
import "@google/model-viewer/dist/model-viewer-legacy";
// utils
import Viewer from './../three/Viewer'

const API_URL = process.env.REACT_APP_API_URL;

class FfilePreview extends Component {
	constructor(props) {
        super(props);
        this.state = {
			current:null,
			loading:true,
			blob:null,
			format:null,
			subffile:null
		};
	}

	componentDidMount() {
		
	}

	load(){
		const { select, ffile } = this.props;

		let format = null;

		if (select && ffile) {
			let found = null;
			for (let subffile of ffile.ffile.data.subffiles) {
				if (subffile._id === select) {
					found = subffile;
				}
			}
			if(found){
				format = found.mimetype;
				let url = API_URL+"/subffile/get/" + found.path.split("\\")[1];
				fetch(url).then(r => r.blob()).then((b)=>{
					this.setState({
						...this.state,
						loading:false,
						blob:b,
						format:format,
						subffile:found
					})
				});
			}
		}
	}

	componentDidUpdate() {
		const { select } = this.props;

		if(this.state.current == null){
			if(select !== null){
				this.setState({
					...this.state,
					current:select
				})
				this.load();
			}
		}else if(this.state.current !== select){
			this.setState({
				...this.state,
				current:select,
				loading:true
			});
			this.load();
		}		
	}

	renderImage(blob) {
		let imageUrl = null;
		if(blob){
			imageUrl = URL.createObjectURL(blob);
		}
		return (
			<img src={imageUrl} alt="" width="100%" />
		)
	}

	renderVideo(blob) {
		let videoUrl = null;
		if(blob){
			videoUrl = URL.createObjectURL(blob);
		}
		return (
			<video
				src={videoUrl}
				alt=""
				width="100%"
				controls
			/>
		)
	}

	renderFbx(blob){
		let src = null;
		if(blob){
			src = URL.createObjectURL(blob);
		}
		return (
			<Viewer
				asset={src}
			/>
		)
	}

	renderGlb(blob){
		let src = null;
		if(blob){
			src = URL.createObjectURL(blob);
		}
		return (
			<model-viewer
				style={{width:"100%", height:"300px"}}
				src={src}
				camera-controls
				//ios-src={}
			>
			</model-viewer>
		)
	}

	switch(subffile, blob) {
		switch (subffile.mimetype) {
			case "image/jpeg":
				return this.renderImage(blob)
			case "image/png":
				return this.renderImage(blob)
			case "video/mp4":
				return this.renderVideo(blob)
			case "application/octet-stream":
				if(subffile.channel === "fbx"){
					return this.renderFbx(blob)
				}else{
					return this.renderGlb(blob)
				}				
			default:
				return (
					"format non pris en charge"
				)
		}
	}

	render() {
		const { loading, blob, subffile } = this.state;

		return (
			<React.Fragment>
				{/* Preview */}
				<TileContainer>
					<Tile size={"full"}>
						<TileHeader title="Aperçu" border={true} />
						<TileBody>
							{loading ? (
								<React.Fragment>
									Chargement
								</React.Fragment>
							) : this.switch(subffile, blob)
							}
							
						</TileBody>
					</Tile>
				</TileContainer>
			</React.Fragment>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{

		},
		dispatch
	);
}

function mapStateToProps(state) {
	return {
		ffile: state.ffile,
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(FfilePreview);